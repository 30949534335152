<template>
  <div>
    <ele-table
      ref="rt"
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :current-page="pageNumber"
      :hide-row-selection="false"
      :actions-type="actionsType"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
      @emitLinkClick="handlePriceClick"
    >
      <!-- 按钮 -->
      <a-row :gutter="8">
        <a-col :span="4">
          <a-input
            v-model="searchFormInline.ProjectName"
            placeholder="请选择项目"
            @click="selectProjectModal"
          >
          </a-input>
        </a-col>
        <a-col :span="4">
          <a-input
            v-model="searchFormInline.TaskName"
            placeholder="请选择任务"
            @click="selectTaskModal"
            
          >
          </a-input>
        </a-col>
        <a-col :span="4">
          <a-select
            v-model="searchFormInline.ClientId"
            style="width: 100%"
            show-search
            :filter-option="filterOption"
            placeholder="请选择客户"
            allowClear
          >
            <a-select-option
              v-for="item in Customers"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="4">
          <a-select
            v-model="searchFormInline.Status"
            style="width: 100%"
            :placeholder="l('PleaseSelectStatus')"
            allowClear
          >
            <a-select-option v-for="item in SettlementStatus" :key="item.value">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="4">
          <a-button
            type="primary"
            @click="searchHandler"
            @keyup.enter="searchHandler"
            v-if="isGranted('Pages.LGModul.Settlement.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="clearFilterAndRefresh"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" style="text-align: right" :span="4">
          <a-button-group>
            <a-button
              type="primary"
              :disabled="!hasSelected"
              @click="submitSettlement()"
              v-if="isGranted('Pages.LGModule.Settlement.SubmitSettlement')"
            >
              <a-icon type="cloud-upload" />
              {{ l("SubmitSettlement") }}
            </a-button>
            <a-button
              type="success"
              :disabled="!hasSelected"
              @click="batchEditPrice()"
              v-if="isGranted('Pages.LGModule.Settlement.SubmitSettlement')"
            >
              <a-icon type="form" />
              {{ l("批量修改金额") }}
            </a-button>
          </a-button-group>
        </a-col>
      </a-row>
    </ele-table>
    <a-modal
      :title="l('HintInformation')"
      :visible="showSettlementInfo"
      @cancel="hideSettlementInfo"
      :width="805"
    >
      <a-row :gutter="24">
        <a-col span="24">
          <a-table
            bordered
            :data-source="settlementErrorResult"
            :pagination="false"
          >
            <a-table-column
              key="realName"
              :title="l('NameSurname')"
              data-index="realName"
              :ellipsis="true"
              width="75px"
            ></a-table-column>
            <a-table-column
              key="phoneNumber"
              :title="l('ProjectUserPhone')"
              data-index="phoneNumber"
              :ellipsis="true"
              width="150px"
            ></a-table-column>
            <a-table-column
              key="idCard"
              :title="l('ProjectUserIdCard')"
              data-index="idCard"
              :ellipsis="true"
              width="190px"
            ></a-table-column>
            <a-table-column
              key="thisTimeAmount"
              :title="l('SettlementAmount')"
              data-index="thisTimeAmount"
              :ellipsis="true"
              width="90px"
            ></a-table-column>
            <a-table-column
              key="remarks"
              :title="l('MessageError')"
              data-index="remarks"
              :ellipsis="true"
              width="250px"
            ></a-table-column>
          </a-table>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="hideSettlementInfo">{{
          l("Close")
        }}</a-button>
      </template>
    </a-modal>
    <a-modal
      title="修改结算金额"
      :visible="editPriceVisible"
      :confirm-loading="editPriceLoading"
      @ok="editPrice"
      @cancel="cancelEdit"
      ok-text="保存" cancel-text="关闭"
    >
      <a-row :gutter="24">
        <a-col :span="22" :offset="1">
          <a-form>
            <a-form-item label="结算金额" prop="price">
              <a-input-number name="price" v-model="editPriceFormModel.price" :step="0.01" placeholder="请输入结算金额" />
            </a-form-item>
            <a-form-item label="备注" prop="remark">
              <a-textarea v-model="editPriceFormModel.remark" name="remark" :rows="4" placeholder="请输入结算金额" />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-modal>
    <a-modal
      title="结算金额修改记录"
      :visible="editPriceLogVisible"
      @ok="editPriceClose"
      @cancel="editPriceClose"
      ok-text="确定" cancel-text="关闭"
    >
      <a-row :gutter="24">
        <a-col :span="22" :offset="1">
          <div v-html="editPriceLogText"></div>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import EleTable from "@/components/ele-table";
import SelectPageList from "@/components/linggong/select-page-list";
import { message } from "ant-design-vue";

export default {
  name: "settlementModule",
  mixins: [AppComponentBase],
  components: { EleTable },
  data: function () {
    return {
      spinning: false,
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 },
      searchFormInline: {
        ProjectId: "",
        ProjectName: "",
        TaskId: "",
        TaskName: "",
        ClientId: undefined,
        Status: undefined,
        SkipCount: 0,
        MaxResultCount: 30,
      },
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      SettlementStatus: [
        // { text: this.l("PleaseSelectStatus"), value: 0 },
        { text: this.l("WaitForSettlement"), value: 1 },
        { text: this.l("Settlemented"), value: 2 },
      ],
      columns: [
        {
          title: this.l("ReceiverName"),
          dataIndex: "realName",
          align: "center",
          ellipsis: true,
          fixed: "left",
          width: "75px",
        },
        {
          title: this.l("结算金额"),
          dataIndex: "price",
          align: "center",
          fixed: "left",
          ellipsis: true,
          type: 'tag',
          width: 130,
        },
        {
          title: this.l("Status"),
          dataIndex: "isAuditedStr",
          align: "center",
          fixed: "left",
          // width: "90px",
        },
        {
          title: this.l("ProjectHeaderName"),
          dataIndex: "projectName",
          align: "center",
          scopedSlots: { customRender: "projectName" },
          ellipsis: true,
          //   width: "150px",
        },
        {
          title: this.l("TaskHeaderName"),
          dataIndex: "taskTitle",
          align: "center",
          ellipsis: true,
        },
        {
          title: this.l("ProjectHeaderCustomerName"),
          dataIndex: "customerName",
          align: "center",
          ellipsis: true,
          //   width: "150px",
        },
        {
          title: this.l("BankAccountNo"),
          dataIndex: "bankCardNo",
          align: "center",
          ellipsis: true,
          // width: "210px",
        },
        {
          title: this.l("BankName"),
          dataIndex: "bankName",
          align: "center",
          ellipsis: true,
          // width: "120px",
        },
        {
          title: this.l("ProjectUserIdCard"),
          dataIndex: "idCard",
          align: "center",
          ellipsis: true,
          // width: "190px",
        },
        {
          title: this.l("ProjectUserPhone"),
          dataIndex: "phoneNumber",
          align: "center",
          ellipsis: true,
          // width: "150px",
        },
        {
          title: '是否提现记录',
          dataIndex: "withdrawal",
          align: "center",
          ellipsis: true
        },
        {
          title: this.l("ApplyDateTime"),
          dataIndex: "auditedTime",
          align: "center",
          customRender: (text, record, index) => {
            var html = text ? text.replace("T", " ") : "待审核";
            if (html.length > 18) {
              html = html.substring(0, 19);
            }
            return html;
          },
          ellipsis: true,
          // width: "190px",
        },
        {
          title: this.l("SettlementDate"),
          dataIndex: "settlementTime",
          align: "center",
          customRender: (text, record, index) => {
            var html = text ? text.replace("T", " ") : "待结算";
            if (html.length > 18) {
              html = html.substring(0, 19);
            }
            return html;
          },
          // width: "190px",
        },
       {
          title: this.l("Action"),
          dataIndex: "actions",
          align: "center",
          fixed: "right",
          // width: 130,
          scopedSlots: { customRender: "actions" },
        },
      ],
      actionsType: {},
      AllProject: [],
      ProjectTasks: [],
      Customers: [],
      showSettlementInfo: false,
      settlementErrorResult: [],
      editPriceVisible: false,
      editPriceLoading: false,
      currentPriceRows: [],
      editPriceFormModel: {
        price: 0,
        remark: ''
      },
      editPriceLogVisible: false,
      editPriceLogText: ''
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  methods: {
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          //   selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/Project/GetSelectPaged", // 数据接口URL
                      searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          this.searchFormInline.ProjectId = data.id;
          this.searchFormInline.ProjectName = data.name;
          // this.getTasksByProjectId(data.id);
          this.getData();
        }
      });
    },
    selectTaskModal() {
      if (this.searchFormInline.ProjectId === "") {
        message.info("请先选择项目.");
        return;
      }
      ModalHelper.create(
        SelectPageList,
        {
          //   selectType: "checkbox", // checkbox radio
          title: "选择任务", // 显示标题
          id: this.searchFormInline.ProjectId,
          url: "/api/services/app/Task/GetSelectPaged", // 数据接口URL
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          this.searchFormInline.TaskId = data.id;
          this.searchFormInline.TaskName = data.name;
          // this.getTasksByProjectId(data.id);
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    projectChange(val) {
      this.searchFormInline.ProjectId = val;
      this.getData();
      // this.getTasksByProjectId(val);
    },
    getAllProject() {
      this.spinning = true;
      this.$api
        .request({
          url: this.$apiUrl + "/api/services/app/Project/GetAllProject",
          method: "GET",
        })
        .then((res) => {
          this.AllProject.splice(0, this.AllProject.length);
          if (res && res.data) {
            this.AllProject = [...res.data];
            this.searchFormInline.ProjectId = res.data[0].id;
            this.searchFormInline.ProjectName = res.data[0].projectName;
            this.getData();
          }
        })
        .catch((e) => {
          //   abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    getAllCutomer() {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
              }
            }
          }
          //   console.info(res);
        })
        .catch((e) => {
          console.info(e);
        });
    },
    getTasksByProjectId(projectId) {
      this.$api
        .request({
          url:
            this.$apiUrl + "/api/services/app/Task/GetTaskTemplateByProjectId",
          method: "GET",
          params: { projectId: projectId },
        })
        .then((res) => {
          this.ProjectTasks.splice(1, this.ProjectTasks.length - 1);
          if (res && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.ProjectTasks.push(res.data[i]);
            }
            this.searchFormInline.TaskId = "";
          }
        })
        .catch((e) => {
          //   abp.message.error(this.l("Error500Desc"));
          console.error(e);
        });
    },
    submitSettlement() {
      if (!this.selectedRows.length) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return;
      }
      if (this.selectedRows.filter((item) => item.status !== 1).length) {
        abp.message.warn(this.l(`只有状态为"审核通过"的数据可以提交结算`));
        return;
      }
      if (this.selectedRows.filter(item => item.isWithdrawal === true).length > 0) {
        abp.message.warn(this.l("提现记录无需提交结算"));
        return;
      }
      const ids = this.selectedRows.map((item) => {
        return item.id;
      });
      this.spinning = true;
      abp.message.confirm("是否提交结算所选数据？", "系统提示", (result) => {
        if (result) {
          let options = {
            url:
              this.$apiUrl + "/api/services/app/PersonnelTask/GenerateSalary",
            method: "POST",
            data: { recordIds: ids },
          };
          this.$api
            .request(options)
            .then((res) => {
              if (res.status == 200) {
                let data = res.data;
                if (data && data.length > 0) {
                  this.showSettlementInfo = true;
                  this.settlementErrorResult.splice(
                    0,
                    this.settlementErrorResult.length
                  );
                  this.settlementErrorResult = data;
                } else {
                  abp.message.success(this.l("SuccessfullyOperation"));
                }
                this.getData();
              }
            })
            .catch((e) => {
              // abp.message.error(this.l("Error500Desc"));
              console.info(e);
            })
            .finally(() => {
              this.spinning = false;
            });
        } else {
          this.spinning = false;
        }
      });
    },
    searchHandler() {
      this.searchFormInline.SkipCount = 0;
      this.getData();
    },
    getData() {
      if (this.searchFormInline.ProjectId == "") {
        return false;
      }
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      })
      this.selectedRows = [];
      this.selectedRowKeys = [];
      this.spinning = true;
      let options = {
        url:
          this.$apiUrl + "/api/services/app/PersonnelTask/GetSettlementPages",
        method: "POST",
        data: {
          projectId: this.searchFormInline.ProjectId,
          taskId:
            this.searchFormInline.TaskId == ""
              ? null
              : this.searchFormInline.TaskId,
          customerId:
            this.searchFormInline.ClientId == ""
              ? null
              : this.searchFormInline.ClientId,
          status:
            this.searchFormInline.Status == 0
              ? null
              : this.searchFormInline.Status,
          skipCount: this.searchFormInline.SkipCount,
          maxResultCount: this.searchFormInline.MaxResultCount,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              2 / this.searchFormInline.MaxResultCount
            );
            // this.totalPages =
            //   (data.totalCount + this.searchFormInline.MaxResultCount - 1) /
            //   this.searchFormInline.MaxResultCount;
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.searchFormInline.MaxResultCount + 1,
              this.pageNumber * this.searchFormInline.MaxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              let auditStatus = this.l("WaitForApply");
              if (item.status == 1) {
                auditStatus = this.l("ApplyPass");
              } else if (item.status == 2) {
                auditStatus = this.l("Settlemented");
              } else if (item.status == -1) {
                auditStatus = this.l("NotPass");
              }
              return {
                ...item,
                priceColor: "#87d068",
                executeTimeStr: item.executeTime
                  ? moment(item.executeTime).format("YYYY-MM-DD")
                  : "-",
                isAuditedStr: auditStatus,
                auditedTime: item.auditedTime ? moment(item.auditedTime).format("YYYY-MM-DD") : '待审核',
                settlementTime: item.settlementTime ? moment(item.settlementTime).format("YYYY-MM-DD") : '待结算',
              };
            });
          }
        })
        .catch((e) => {
          // abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    clearFilterAndRefresh() {
      // this.searchFormInline.SkipCount = 0;
      this.searchFormInline = {
        // ProjectId: "",
        // ProjectName: "",
        ...this.searchFormInline,
        TaskId: "",
        TaskName: "",
        ClientId: undefined,
        Status: undefined,
        SkipCount: 0,
        MaxResultCount: 30,
      };
      this.ProjectTasks = [];
      this.getData();
    },
    //
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.searchFormInline.SkipCount = 0;
      this.searchFormInline.MaxResultCount = size;
      this.getData();
    },
    //
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRows = selectedRows;
      this.selectedRowKeys = selectedRowKeys;

      console.log(selectedRowKeys);
      console.log(selectedRows);

      // this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows.filter((p) => p.status !== 2);
    },
    //
    updatePageChange(newV) {
      let { page, pageSize, skipCount } = newV;
      this.onChange(page, skipCount);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.searchFormInline.SkipCount = (page - 1) * pageSize;
      this.searchFormInline.MaxResultCount = pageSize;
      this.getData();
    },
    hideSettlementInfo() {
      this.showSettlementInfo = false;
    },
    handlePriceClick(row) {
      if (row.item.status === 1) {
        this.currentPriceRows.splice(0, this.currentPriceRows.length);
        this.currentPriceRows.push(row.item);
        this.$form.createForm(this, this.editPriceFormModel);
        this.editPriceFormModel.price = row.item.price;
        this.editPriceFormModel.remark = '';
        this.editPriceVisible = true;
      }
    },
    editPrice(){
      this.editPriceLoading = true;
      if (this.editPriceFormModel.price <= 0 || this.editPriceFormModel.price > 92000) {
        abp.message.warn("结算金额不能是0或者负数，且不能大于92000");
        this.editPriceLoading = false;
        return false;
      }
      if (!this.editPriceFormModel.remark || this.editPriceFormModel.remark === '') {
        abp.message.warn("请填写备注");
        this.editPriceLoading = false;
        return false;
      }
      let ids = this.currentPriceRows.map(item => item.id);
      let options = {
        url: this.$apiUrl + "/api/services/app/PersonnelTask/UpdateExecutionAmount",
        method: "POST",
        data: {
          taskExecuteIds: ids,
          amount: this.editPriceFormModel.price,
          remark: this.editPriceFormModel.remark
        }
      };
      this.$api
        .request(options)
        .then((res) => {
          console.info(res);
          if (res.status == 200) {
            abp.message.success(res.data);
            this.currentPriceRows.splice(0, this.currentPriceRows.length);
            this.editPriceFormModel.price = 0;
            this.editPriceFormModel.remark = '';
            this.editPriceVisible = false;
            this.getData();
          } else {
            abp.message.error(res.statusText);
          }
        }).finally(() => {
          this.editPriceLoading = false;
        });
    },
    cancelEdit() {
      this.editPriceVisible = false;
      this.editPriceLoading = false;
    },
    editPriceClose() {
      this.editPriceLogVisible = false;
    },
    batchEditPrice(){
      this.spinning = true;
      let errors = this.selectedRows.filter(it => it.status !== 1 && it.status !== -1);
      if (errors && errors.length > 0) {
        abp.message.warn('只有[待结算]和[审核驳回]的数据才能修改结算金额');
        this.spinning = false;
        return false;
      }
      const ids = this.selectedRows.map((item) => {
        return item.id;
      });
      if (!ids || ids.length <= 0) {
        abp.message.warn('请至少选择一条记录');
        this.spinning = false;
        return false;
      }


      this.currentPriceRows.splice(0, this.currentPriceRows.length);
      this.selectedRows.forEach((item, index) => {
        this.currentPriceRows.push(item);
      });
      this.$form.createForm(this, this.editPriceFormModel);
      this.editPriceVisible = true;
    }
  },
  created() {
    if (this.$route.query && this.$route.query.status) {
      this.searchFormInline.Status = Number(this.$route.query.status);
    }
    this.getAllCutomer();
    message.info("请选择一个项目进行查询.");
    this.editForm = this.$form.createForm(this, { name: "editForm1" });
    let _this = this;
    this.actionsType = {
        type: "project-user",
        isShow: true,
        fns: {
          detailList: [
            {
              name: this.l("金额修改记录"),
              icon: "profile",
              granted: true,
              fn: (data) => {
                _this.$api.request({
                  url: _this.$apiUrl + "/api/services/app/PersonnelTask/GetAmountOfEditLog",
                  method: "GET",
                  params: {
                    id: data.id
                  }
                }).then(res => {
                  if (res.status === 200) {
                    let data = res.data ? res.data : "";
                    data = data.replace(/\r\n/g, "<br>").replace(/\n/g,"<br>");
                    this.editPriceLogText = data;
                    this.editPriceLogVisible = true;
                  }
                });
              },
            },
          ]
        },
      };
  },
};
</script>
<style lang="less" scoped>
// .btn--container {
//   margin-top: 20px;
// }

// .btn--footer {
//   margin-bottom: 20px;
// }
</style>
